import React from 'react';

interface DeleteIconProps {
  className?: string;
}

const DeleteIcon: React.FC<DeleteIconProps> = ({ className }) => (
  <svg 
    width="16" 
    height="16" 
    viewBox="0 0 16 16" 
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M4.34996 14C4.07496 14 3.83954 13.9021 3.64371 13.7063C3.44788 13.5104 3.34996 13.275 3.34996 13V3.5H2.66663V2.5H5.79996V2H10.2V2.5H13.3333V3.5H12.65V13C12.65 13.2667 12.55 13.5 12.35 13.7C12.15 13.9 11.9166 14 11.65 14H4.34996ZM11.65 3.5H4.34996V13H11.65V3.5ZM6.11663 11.5667H7.11663V4.91667H6.11663V11.5667ZM8.88329 11.5667H9.88329V4.91667H8.88329V11.5667Z" />
  </svg>
);

export default DeleteIcon;
