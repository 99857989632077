const EnableSound = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" fill="#1E1E1E"/>
      <g id="Notification Settings">
        <path d="M-1014 -373C-1014 -374.105 -1013.1 -375 -1012 -375H544C545.105 -375 546 -374.105 546 -373V83C546 84.1046 545.105 85 544 85H-1012C-1013.1 85 -1014 84.1046 -1014 83V-373Z" fill="#313131"/>
        <path d="M-1012 -374H544V-376H-1012V-374ZM545 -373V83H547V-373H545ZM544 84H-1012V86H544V84ZM-1013 83V-373H-1015V83H-1013ZM-1012 84C-1012.55 84 -1013 83.5523 -1013 83H-1015C-1015 84.6568 -1013.66 86 -1012 86V84ZM545 83C545 83.5523 544.552 84 544 84V86C545.657 86 547 84.6569 547 83H545ZM544 -374C544.552 -374 545 -373.552 545 -373H547C547 -374.657 545.657 -376 544 -376V-374ZM-1012 -376C-1013.66 -376 -1015 -374.657 -1015 -373H-1013C-1013 -373.552 -1012.55 -374 -1012 -374V-376Z" fill="white" fillOpacity="0.1"/>
        <g id="Notification Settings_2">
          <rect x="-406.5" y="-351.5" width="564" height="402" rx="3.5" fill="white"/>
          <rect x="-406.5" y="-351.5" width="564" height="402" rx="3.5" stroke="#F5F6FF"/>
          <g id="Frame 1000005072">
            <g id="Frame 1000005074">
              <g id="volume_up_FILL0_wght300_GRAD0_opsz24 1">
                <path id="Vector" d="M18.8462 11.975C18.8462 10.6001 18.4777 9.34523 17.7409 8.21048C17.0041 7.07573 16.0161 6.22673 14.7769 5.6635C14.5846 5.57247 14.4429 5.43657 14.3519 5.2558C14.2609 5.07504 14.2498 4.89068 14.3185 4.70273C14.401 4.50223 14.5442 4.36288 14.7481 4.28468C14.9519 4.20648 15.1583 4.21289 15.3673 4.3039C16.875 4.98852 18.0817 6.01852 18.9874 7.3939C19.8932 8.7693 20.3461 10.2962 20.3461 11.9747C20.3461 13.6531 19.8932 15.1801 18.9874 16.5558C18.0817 17.9314 16.875 18.9615 15.3673 19.6462C15.1583 19.7372 14.9519 19.7436 14.7481 19.6654C14.5442 19.5872 14.401 19.4478 14.3185 19.2473C14.2498 19.0594 14.2609 18.875 14.3519 18.6943C14.4429 18.5135 14.5846 18.3776 14.7769 18.2866C16.0161 17.7233 17.0041 16.8743 17.7409 15.7396C18.4777 14.6048 18.8462 13.35 18.8462 11.975ZM7.3654 14.5H4.55773C4.30164 14.5 4.08698 14.4134 3.91375 14.2401C3.74052 14.0669 3.6539 13.8522 3.6539 13.5962V10.4039C3.6539 10.1478 3.74052 9.93316 3.91375 9.75993C4.08698 9.58668 4.30164 9.50005 4.55773 9.50005H7.3654L10.3577 6.50778C10.5974 6.26804 10.8734 6.21394 11.1856 6.34545C11.4977 6.47699 11.6538 6.71186 11.6538 7.05008V16.95C11.6538 17.2882 11.4977 17.5231 11.1856 17.6546C10.8734 17.7861 10.5974 17.732 10.3577 17.4923L7.3654 14.5ZM16.1538 12.0011C16.1538 12.6249 16.0243 13.2153 15.7654 13.7723C15.5064 14.3292 15.1573 14.7974 14.7182 15.1769C14.5766 15.2718 14.4279 15.2814 14.2721 15.2058C14.1164 15.1301 14.0385 15.0051 14.0385 14.8308V9.11928C14.0385 8.94493 14.1164 8.81993 14.2721 8.74428C14.4279 8.66864 14.5766 8.67773 14.7182 8.77153C15.1573 9.16234 15.5064 9.64236 15.7654 10.2116C16.0243 10.7808 16.1538 11.3773 16.1538 12.0011ZM10.1539 8.85003L8.00385 11H5.15385V13H8.00385L10.1539 15.15V8.85003Z" fill="#5468FA"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default EnableSound