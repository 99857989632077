const EmptyStateIllustration = ({height = 160, width = 160, primary_light = '#CCD2FD'}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
      <path opacity="0.4" d="M74.2143 142.564C111.52 142.564 141.762 132.687 141.762 120.502C141.762 108.318 111.52 98.4412 74.2143 98.4412C36.9087 98.4412 6.66663 108.318 6.66663 120.502C6.66663 132.687 36.9087 142.564 74.2143 142.564Z" fill="#E6E6E6"/>
      <path d="M30.7241 58.2189V117.107L75.4183 130.374V70.3676L30.7241 58.2189Z" fill={primary_light}/>
      <path d="M117.841 119.023L75.418 130.374V70.3676L117.841 58.2189V119.023Z" fill={primary_light}/>
      <path d="M30.7241 58.2189V117.107L75.4183 130.374V70.3676L30.7241 58.2189Z" fill="black" fillOpacity="0.15"/>
      <path d="M117.841 119.023L75.418 130.374V70.3676L117.841 58.2189V119.023Z" fill="black" fillOpacity="0.06"/>
      <path d="M30.7241 58.2204L75.4183 46.9943L117.841 58.2204L75.4183 70.3692L30.7241 58.2204Z" fill={primary_light}/>
      <path d="M30.7241 58.2201L75.4183 46.9939L117.841 58.2201L75.4183 70.3688L30.7241 58.2201Z" fill="black" fillOpacity="0.2"/>
      <path d="M131.419 70.3676L87.8926 82.7219L75.418 70.3676L117.841 58.2189L131.419 70.3676Z" fill={primary_light}/>
      <path d="M18.9863 70.4729L30.7239 58.2189L75.4181 70.3676L63.1992 82.7219L18.9863 70.4729Z" fill={primary_light}/>
      <path d="M75.4178 46.9932L90.6151 38.2589L131.418 49.5202L117.841 58.2194L75.4178 46.9932Z" fill={primary_light}/>
      <path d="M18.9863 48.237L61.9356 38.2593L75.4181 46.9935L30.7239 58.2197L18.9863 48.237Z" fill={primary_light}/>
      <path d="M131.419 70.3676L87.8926 82.7219L75.418 70.3676L117.841 58.2189L131.419 70.3676Z" fill={primary_light}/>
      <path d="M18.9863 70.4729L30.7239 58.2189L75.4181 70.3676L63.1992 82.7219L18.9863 70.4729Z" fill={primary_light}/>
      <path d="M75.4178 46.9932L90.6151 38.2589L131.418 49.5202L117.841 58.2194L75.4178 46.9932Z" fill={primary_light}/>
      <path d="M18.9863 48.2366L61.9356 38.2589L75.4181 46.9932L30.7239 58.2194L18.9863 48.2366Z" fill={primary_light}/>
      <path d="M75.2 47.0402V70.4151L117.623 58.2663L75.2 47.0402Z" fill="black" fillOpacity="0.3"/>
      <path d="M78.9927 60.7157C78.6317 52.7185 79.3688 25.7436 105.18 21.4918C106.86 21.216 108.58 21.216 110.26 21.5068C112.506 21.8979 115.63 22.7904 118.177 24.831C122.714 28.4611 119.992 36.2879 113.639 34.6433C107.286 32.9987 107.286 19.3859 119.084 17.5709C128.225 16.167 134.096 21.2962 136.277 23.6778" stroke="#B8B8B8" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="4 4"/>
      <path d="M140.027 28.8209C140.027 28.8209 138.684 29.9807 137.669 28.6762C137.492 28.449 137.418 28.1568 137.486 27.8764C137.586 27.4603 137.925 26.9261 139 27.0028C139 27.0028 138.321 24.3407 139.519 23.8478C141.314 23.107 141.042 26.8611 141.042 26.8611C141.042 26.8611 144.076 25.049 146.366 25.5065C148.657 25.9639 144.094 28.8268 142.937 28.7412C142.937 28.7412 143.017 31.6512 140.726 31.1938C140.726 31.1938 139.304 30.8012 140.027 28.8209Z" fill="#AAAAAA"/>
    </svg>
  )
}
export default EmptyStateIllustration