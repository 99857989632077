interface NestIconProps {
  className?: string;
}

const NestIcon = ({ className }: NestIconProps) => (
  <svg 
    width="16" 
    height="16" 
    viewBox="0 0 16 16" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path 
      d="M4 9.75V3H4.92308V8.85H10.5392L8.91439 6.6H10.0502L12 9.3L10.0502 12H8.91439L10.5392 9.75H4Z" 
      fill="currentColor"
    />
  </svg>
);

export default NestIcon;
