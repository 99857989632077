const Arrival = ({ width = 12, height = 12 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" style={{ fill: 'currentColor' }} xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M3.15776 3.89058C3.0102 3.89058 2.89058 4.0102 2.89058 4.15776V15.5572C2.89058 15.7048 3.0102 15.8244 3.15776 15.8244H8.8575C9.10343 15.8244 9.30279 16.0238 9.30279 16.2697C9.30279 16.5156 9.10343 16.715 8.8575 16.715H3.15776C2.51835 16.715 2 16.1967 2 15.5572V4.15776C2 3.51835 2.51835 3 3.15776 3H14.5572C15.1967 3 15.715 3.51835 15.715 4.15776V8.7888C15.715 9.03473 15.5156 9.23409 15.2697 9.23409C15.0238 9.23409 14.8244 9.03473 14.8244 8.7888V4.15776C14.8244 4.0102 14.7048 3.89058 14.5572 3.89058H10.7277V9.14503C10.7277 9.32269 10.6221 9.48334 10.4591 9.5538C10.296 9.62426 10.1066 9.59105 9.97725 9.4693L8.95156 8.50394C8.84868 8.40711 8.68821 8.40711 8.58533 8.50394L7.55964 9.4693C7.43027 9.59105 7.24091 9.62426 7.07783 9.5538C6.91475 9.48334 6.80916 9.32269 6.80916 9.14503V3.89058H3.15776ZM7.69974 3.89058V8.11444L7.97496 7.85541C8.42075 7.43584 9.11613 7.43584 9.56193 7.85541L9.83714 8.11444V3.89058H7.69974Z" style={{ fill: 'currentColor' }} />
      <path fillRule="evenodd" clipRule="evenodd" d="M19.3365 13.9009C19.4652 14.0971 19.4106 14.3604 19.2145 14.4892L14.8048 17.3837C14.6121 17.5102 14.3538 17.4599 14.2225 17.2705L13.3233 15.973C13.1897 15.7801 13.2377 15.5155 13.4305 15.3818C13.6234 15.2482 13.888 15.2962 14.0217 15.489L14.6849 16.4461L18.7482 13.7789C18.9444 13.6502 19.2077 13.7048 19.3365 13.9009Z" style={{ fill: 'currentColor' }} />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.9313 20.8141C18.8137 20.8141 21.1504 18.4775 21.1504 15.5951C21.1504 12.7126 18.8137 10.376 15.9313 10.376C13.0489 10.376 10.7122 12.7126 10.7122 15.5951C10.7122 18.4775 13.0489 20.8141 15.9313 20.8141ZM15.9313 21.6638C19.283 21.6638 22 18.9467 22 15.5951C22 12.2434 19.283 9.52637 15.9313 9.52637C12.5797 9.52637 9.86261 12.2434 9.86261 15.5951C9.86261 18.9467 12.5797 21.6638 15.9313 21.6638Z" style={{ fill: 'currentColor' }} />
    </svg>
  )
}

export default Arrival