const Split = ({ width = 12, height = 12 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M3.72174 8.88696C4.21159 8.88696 4.6087 8.48985 4.6087 8C4.6087 7.51015 4.21159 7.11304 3.72174 7.11304C3.23189 7.11304 2.83478 7.51015 2.83478 8C2.83478 8.48985 3.23189 8.88696 3.72174 8.88696ZM3.72174 9.72174C4.67263 9.72174 5.44348 8.95089 5.44348 8C5.44348 7.04911 4.67263 6.27826 3.72174 6.27826C2.77085 6.27826 2 7.04911 2 8C2 8.95089 2.77085 9.72174 3.72174 9.72174Z" fill="currentColor" strokeWidth="0.8" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.2783 4.6087C12.7681 4.6087 13.1652 4.21159 13.1652 3.72174C13.1652 3.23189 12.7681 2.83478 12.2783 2.83478C11.7884 2.83478 11.3913 3.23189 11.3913 3.72174C11.3913 4.21159 11.7884 4.6087 12.2783 4.6087ZM12.2783 5.44348C13.2292 5.44348 14 4.67263 14 3.72174C14 2.77085 13.2292 2 12.2783 2C11.3274 2 10.5565 2.77085 10.5565 3.72174C10.5565 4.67263 11.3274 5.44348 12.2783 5.44348Z" fill="currentColor" strokeWidth="0.8" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.2783 13.1652C12.7681 13.1652 13.1652 12.7681 13.1652 12.2783C13.1652 11.7884 12.7681 11.3913 12.2783 11.3913C11.7884 11.3913 11.3913 11.7884 11.3913 12.2783C11.3913 12.7681 11.7884 13.1652 12.2783 13.1652ZM12.2783 14C13.2292 14 14 13.2292 14 12.2783C14 11.3274 13.2292 10.5565 12.2783 10.5565C11.3274 10.5565 10.5565 11.3274 10.5565 12.2783C10.5565 13.2292 11.3274 14 12.2783 14Z" fill="currentColor" strokeWidth="0.8" />
      <path fillRule="evenodd" clipRule="evenodd" d="M7.52655 8.43824L5.23099 8.41737L5.23858 7.58262L7.53414 7.60349L7.52655 8.43824Z" fill="currentColor" strokeWidth="0.8" />
      <path fillRule="evenodd" clipRule="evenodd" d="M7.32174 3.87826C7.32174 3.53248 7.60205 3.25217 7.94783 3.25217H10.9217V4.08696H8.15652V11.9652H10.9217V12.8H7.94783C7.60205 12.8 7.32174 12.5197 7.32174 12.1739V3.87826Z" fill="currentColor" strokeWidth="0.8" />
    </svg>
  )
}

export default Split