const EditLog = ({ width = 12, height = 12 }) => {

  return (

    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.2333 4.9L11.1 2.76667L11.8 2.06667C11.9889 1.87778 12.225 1.78611 12.5083 1.79167C12.7917 1.79722 13.0278 1.89444 13.2167 2.08333L13.9333 2.8C14.1222 2.98889 14.2167 3.22222 14.2167 3.5C14.2167 3.77778 14.1222 4.01111 13.9333 4.2L13.2333 4.9ZM12.5333 5.6L4.13333 14H2V11.8667L10.4 3.46667L12.5333 5.6Z" fill="#222222" />
    </svg>
  )
}

export default EditLog