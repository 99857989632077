const ReportDownloadingImage = ({primary='#5468FA', primaryLight='#CCD2FD', primaryLighter='#EEF0FE', primaryDark='#495AD9', width=164, height=173}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 164 173" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 34723">
        <g id="Group 28">
          <path id="Path 410" d="M76.6811 2.32754C76.3923 1.64208 75.9089 1.05631 75.2907 0.642637C74.6725 0.228968 73.9466 0.00554584 73.2028 0H3.76392C2.76583 0.000505661 1.80875 0.397223 1.10299 1.10298C0.397233 1.80874 0.000505661 2.7658 0 3.76389V91.6081C0.000505661 92.6062 0.397233 93.5633 1.10299 94.269C1.80875 94.9748 2.76583 95.3715 3.76392 95.372H73.2028C74.2006 95.3707 75.1572 94.9737 75.8628 94.2681C76.5684 93.5625 76.9654 92.606 76.9667 91.6081V3.76517C76.9684 3.2717 76.8713 2.78287 76.6811 2.32754ZM76.1309 91.6075C76.1293 92.3831 75.8205 93.1265 75.2721 93.6749C74.7237 94.2234 73.9803 94.5321 73.2047 94.5336H3.76392C2.98787 94.5336 2.2436 94.2253 1.69484 93.6766C1.14609 93.1278 0.837774 92.3835 0.837774 91.6075V3.76517C0.839287 2.98957 1.14808 2.24617 1.69651 1.69775C2.24494 1.14932 2.98833 0.840548 3.76392 0.839035H73.2028C73.7879 0.841439 74.3591 1.01823 74.8432 1.34682C75.3274 1.67541 75.7026 2.14088 75.9209 2.68376C75.9635 2.79177 76.0002 2.90198 76.031 3.01392C76.0976 3.2603 76.1314 3.51439 76.1315 3.76961L76.1309 91.6075Z" fill={primaryLight}/>
          <path id="Path 411" d="M62.1884 20.7411H41.2729C40.8292 20.7411 40.4035 20.5648 40.0897 20.251C39.7759 19.9372 39.5996 19.5116 39.5996 19.0678C39.5996 18.624 39.7759 18.1984 40.0897 17.8846C40.4035 17.5708 40.8292 17.3945 41.2729 17.3945H62.1877C62.6259 17.4031 63.0432 17.5831 63.35 17.896C63.6568 18.2089 63.8287 18.6296 63.8287 19.0678C63.8287 19.5061 63.6568 19.9268 63.35 20.2397C63.0432 20.5525 62.6265 20.7326 62.1884 20.7411Z" fill={primaryLight}/>
          <path id="Path 412" d="M62.1884 29.5263H41.2729C40.8292 29.5263 40.4035 29.35 40.0897 29.0362C39.7759 28.7224 39.5996 28.2968 39.5996 27.853C39.5996 27.4092 39.7759 26.9836 40.0897 26.6698C40.4035 26.356 40.8292 26.1797 41.2729 26.1797H62.1877C62.6259 26.1882 63.0432 26.3683 63.35 26.6812C63.6568 26.994 63.8287 27.4148 63.8287 27.853C63.8287 28.2912 63.6568 28.712 63.35 29.0248C63.0432 29.3377 62.6265 29.5177 62.1884 29.5263Z" fill={primaryLight}/>
          <path id="Path 413" d="M30.5443 34.5281H15.2565C14.7575 34.5276 14.279 34.3291 13.9261 33.9763C13.5732 33.6234 13.3748 33.1449 13.3743 32.6459V14.6088C13.3748 14.1098 13.5732 13.6313 13.9261 13.2784C14.279 12.9255 14.7575 12.7271 15.2565 12.7266H30.5443C31.0433 12.7272 31.5216 12.9258 31.8745 13.2786C32.2273 13.6314 32.4259 14.1098 32.4265 14.6088V32.6459C32.426 33.1449 32.2275 33.6234 31.8747 33.9763C31.5218 34.3291 31.0433 34.5276 30.5443 34.5281Z" fill={primaryLight}/>
          <path id="Path 414" d="M62.0429 47.5126H14.7751C14.3313 47.5126 13.9057 47.3363 13.5919 47.0225C13.2781 46.7087 13.1018 46.2831 13.1018 45.8393C13.1018 45.3955 13.2781 44.9699 13.5919 44.6561C13.9057 44.3423 14.3313 44.166 14.7751 44.166H62.0429C62.4867 44.166 62.9122 44.3423 63.226 44.6561C63.5398 44.9699 63.7162 45.3955 63.7162 45.8393C63.7162 46.2831 63.5398 46.7087 63.226 47.0225C62.9122 47.3363 62.4867 47.5126 62.0429 47.5126Z" fill={primaryLight}/>
          <path id="Path 415" d="M62.0429 56.2997H14.7751C14.3313 56.2997 13.9057 56.1234 13.5919 55.8096C13.2781 55.4958 13.1018 55.0702 13.1018 54.6264C13.1018 54.1827 13.2781 53.757 13.5919 53.4432C13.9057 53.1294 14.3313 52.9531 14.7751 52.9531H62.0429C62.4867 52.9531 62.9122 53.1294 63.226 53.4432C63.5398 53.757 63.7162 54.1827 63.7162 54.6264C63.7162 55.0702 63.5398 55.4958 63.226 55.8096C62.9122 56.1234 62.4867 56.2997 62.0429 56.2997Z" fill={primaryLight}/>
          <path id="Path 416" d="M62.0429 65.0829H14.7751C14.3313 65.0829 13.9057 64.9066 13.5919 64.5928C13.2781 64.279 13.1018 63.8534 13.1018 63.4096C13.1018 62.9659 13.2781 62.5402 13.5919 62.2264C13.9057 61.9126 14.3313 61.7363 14.7751 61.7363H62.0429C62.4867 61.7363 62.9122 61.9126 63.226 62.2264C63.5398 62.5402 63.7162 62.9659 63.7162 63.4096C63.7162 63.8534 63.5398 64.279 63.226 64.5928C62.9122 64.9066 62.4867 65.0829 62.0429 65.0829Z" fill={primaryLight}/>
          <path id="Path 417" d="M62.0429 73.8681H14.7751C14.3313 73.8681 13.9057 73.6918 13.5919 73.378C13.2781 73.0642 13.1018 72.6386 13.1018 72.1948C13.1018 71.751 13.2781 71.3254 13.5919 71.0116C13.9057 70.6978 14.3313 70.5215 14.7751 70.5215H62.0429C62.4867 70.5215 62.9122 70.6978 63.226 71.0116C63.5398 71.3254 63.7162 71.751 63.7162 72.1948C63.7162 72.6386 63.5398 73.0642 63.226 73.378C62.9122 73.6918 62.4867 73.8681 62.0429 73.8681Z" fill={primaryLight}/>
          <path id="Path 418" d="M62.0429 82.6513H14.7751C14.3313 82.6513 13.9057 82.475 13.5919 82.1612C13.2781 81.8474 13.1018 81.4218 13.1018 80.978C13.1018 80.5342 13.2781 80.1086 13.5919 79.7948C13.9057 79.481 14.3313 79.3047 14.7751 79.3047H62.0429C62.4867 79.3047 62.9122 79.481 63.226 79.7948C63.5398 80.1086 63.7162 80.5342 63.7162 80.978C63.7162 81.4218 63.5398 81.8474 63.226 82.1612C62.9122 82.475 62.4867 82.6513 62.0429 82.6513Z" fill={primaryLight}/>
        </g>
        <path id="Path 336" d="M118.619 54.1943C118.252 53.323 117.638 52.5783 116.852 52.0526C116.066 51.5269 115.143 51.2431 114.198 51.2363H25.953C24.6845 51.237 23.4682 51.7412 22.5713 52.6382C21.6743 53.5351 21.1701 54.7514 21.1694 56.0199V167.658C21.1701 168.927 21.6743 170.143 22.5713 171.04C23.4682 171.937 24.6845 172.441 25.953 172.442H114.201C115.469 172.44 116.685 171.935 117.582 171.039C118.479 170.142 118.983 168.926 118.985 167.658V56.0199C118.987 55.3933 118.864 54.7726 118.622 54.1943H118.619Z" fill={primaryLighter}/>
        <path id="Vector" d="M111.433 166.174H28.9646C27.7763 166.173 26.8457 164.873 26.8457 163.214V59.655C26.8469 57.9952 27.7781 56.6953 28.9646 56.6953H111.433C112.622 56.6969 113.553 57.9967 113.553 59.655V163.214C113.552 164.874 112.621 166.174 111.433 166.174Z" fill="white"/>
        <path id="Path 336_2" d="M118.619 54.1943C118.252 53.323 117.638 52.5783 116.852 52.0526C116.066 51.5269 115.143 51.2431 114.198 51.2363H25.953C24.6845 51.237 23.4682 51.7412 22.5713 52.6382C21.6743 53.5351 21.1701 54.7514 21.1694 56.0199V167.658C21.1701 168.927 21.6743 170.143 22.5713 171.04C23.4682 171.937 24.6845 172.441 25.953 172.442H114.201C115.469 172.44 116.685 171.935 117.582 171.039C118.479 170.142 118.983 168.926 118.985 167.658V56.0199C118.987 55.3933 118.864 54.7726 118.622 54.1943H118.619ZM117.919 167.658C117.918 168.645 117.526 169.59 116.828 170.288C116.13 170.986 115.185 171.378 114.198 171.379H25.953C24.9664 171.379 24.0201 170.987 23.3223 170.29C22.6245 169.592 22.2322 168.646 22.2317 167.659V56.0206C22.2331 55.034 22.6256 54.0883 23.3232 53.3907C24.0207 52.6931 24.9665 52.3006 25.953 52.2993H114.201C114.944 52.3021 115.67 52.5263 116.285 52.9432C116.9 53.3602 117.377 53.9509 117.655 54.6402C117.709 54.7775 117.756 54.9177 117.795 55.06C117.88 55.3732 117.922 55.6962 117.922 56.0206L117.919 167.658Z" fill={primaryDark}/>
        <path id="Path 337" d="M100.203 77.5949H73.6226C73.0586 77.5949 72.5178 77.3708 72.119 76.972C71.7202 76.5732 71.4961 76.0323 71.4961 75.4683C71.4961 74.9043 71.7202 74.3635 72.119 73.9647C72.5178 73.5659 73.0586 73.3418 73.6226 73.3418H100.203C100.767 73.3418 101.308 73.5659 101.707 73.9647C102.106 74.3635 102.33 74.9043 102.33 75.4683C102.33 76.0323 102.106 76.5732 101.707 76.972C101.308 77.3708 100.767 77.5949 100.203 77.5949Z" fill={primaryLight}/>
        <path id="Path 338" d="M100.203 88.757H73.6226C73.0586 88.757 72.5178 88.5329 72.119 88.1341C71.7202 87.7353 71.4961 87.1944 71.4961 86.6304C71.4961 86.0664 71.7202 85.5255 72.119 85.1267C72.5178 84.7279 73.0586 84.5039 73.6226 84.5039H100.203C100.767 84.5039 101.308 84.7279 101.707 85.1267C102.106 85.5255 102.33 86.0664 102.33 86.6304C102.33 87.1944 102.106 87.7353 101.707 88.1341C101.308 88.5329 100.767 88.757 100.203 88.757Z" fill={primaryLight}/>
        <path id="Path 339" d="M59.9844 95.1141H40.5594C39.9251 95.1135 39.317 94.8612 38.8684 94.4127C38.4199 93.9641 38.1677 93.356 38.167 92.7217V69.7987C38.1677 69.1644 38.4199 68.5562 38.8684 68.1077C39.317 67.6592 39.9251 67.4069 40.5594 67.4062H59.9844C60.6187 67.4069 61.2269 67.6592 61.6754 68.1077C62.1239 68.5562 62.3762 69.1644 62.3769 69.7987V92.7217C62.3762 93.356 62.1239 93.9641 61.6754 94.4127C61.2269 94.8612 60.6187 95.1135 59.9844 95.1141Z" fill={primary}/>
        <path id="Path 340" d="M100.019 111.618H39.9468C39.3828 111.618 38.842 111.394 38.4431 110.995C38.0443 110.597 37.8203 110.056 37.8203 109.492C37.8203 108.928 38.0443 108.387 38.4431 107.988C38.842 107.589 39.3828 107.365 39.9468 107.365H100.019C100.583 107.365 101.124 107.589 101.523 107.988C101.921 108.387 102.146 108.928 102.146 109.492C102.146 110.056 101.921 110.597 101.523 110.995C101.124 111.394 100.583 111.618 100.019 111.618Z" fill={primaryLighter}/>
        <path id="Path 341" d="M100.019 122.78H39.9469C39.3829 122.782 38.8414 122.559 38.4415 122.162C38.0415 121.764 37.816 121.224 37.8145 120.66C37.8129 120.096 38.0354 119.554 38.4331 119.154C38.8308 118.754 39.3711 118.529 39.9351 118.527H100.019C100.583 118.527 101.124 118.751 101.523 119.15C101.921 119.549 102.146 120.09 102.146 120.654C102.146 121.218 101.921 121.759 101.523 122.158C101.124 122.556 100.583 122.78 100.019 122.78Z" fill={primaryLighter}/>
        <path id="Path 342" d="M100.019 133.946H39.9468C39.3828 133.946 38.842 133.722 38.4431 133.324C38.0443 132.925 37.8203 132.384 37.8203 131.82C37.8203 131.256 38.0443 130.715 38.4431 130.316C38.842 129.917 39.3828 129.693 39.9468 129.693H100.019C100.583 129.692 101.124 129.914 101.524 130.312C101.924 130.71 102.15 131.25 102.151 131.814C102.153 132.378 101.93 132.919 101.533 133.319C101.135 133.719 100.595 133.945 100.031 133.946H100.019Z" fill={primaryLighter}/>
        <path id="Path 343" d="M100.019 145.109H39.9469C39.6676 145.109 39.391 145.055 39.1327 144.949C38.8744 144.843 38.6395 144.687 38.4415 144.49C38.2434 144.293 38.0862 144.059 37.9786 143.801C37.871 143.544 37.8152 143.267 37.8145 142.988C37.8137 142.709 37.8679 142.432 37.9741 142.174C38.0802 141.915 38.2362 141.68 38.4331 141.482C38.6301 141.284 38.864 141.127 39.1217 141.02C39.3795 140.912 39.6558 140.856 39.9351 140.855H100.019C100.583 140.855 101.124 141.08 101.523 141.478C101.921 141.877 102.146 142.418 102.146 142.982C102.146 143.546 101.921 144.087 101.523 144.486C101.124 144.884 100.583 145.109 100.019 145.109Z" fill={primaryLighter}/>
        <path id="Path 344" d="M100.019 156.273H39.9469C39.3829 156.274 38.8414 156.052 38.4415 155.654C38.0415 155.256 37.816 154.716 37.8145 154.152C37.8129 153.588 38.0354 153.046 38.4331 152.647C38.8308 152.247 39.3711 152.021 39.9351 152.02H100.019C100.583 152.02 101.124 152.244 101.523 152.642C101.921 153.041 102.146 153.582 102.146 154.146C102.146 154.71 101.921 155.251 101.523 155.65C101.124 156.049 100.583 156.273 100.019 156.273Z" fill={primaryLighter}/>
        <path id="Ellipse 44" d="M129.65 77.3703C148.621 77.3703 164 61.9912 164 43.0201C164 24.049 148.621 8.66992 129.65 8.66992C110.679 8.66992 95.2996 24.049 95.2996 43.0201C95.2996 61.9912 110.679 77.3703 129.65 77.3703Z" fill={primary}/>
        <path id="Path 692" d="M135.06 44.1595V25.209H125.301V44.1595H120.744L125.462 52.3304L130.18 60.5025L134.899 52.3304L139.617 44.1582L135.06 44.1595Z" fill="white"/>
      </g>
    </svg>
  );
}

export default ReportDownloadingImage;
