const OnHandBreakdownSvg = ({ height, width, iswhite, fillColor }) => {
  const color = iswhite ? 'white' : fillColor;
  return <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_320_162)">
      <path fillRule="evenodd" clipRule="evenodd" d="M15.5081 0.109499C15.8198 -0.0364998 16.1802 -0.0364998 16.4919 0.109499L31.3324 7.06184C31.7398 7.25268 32 7.66192 32 8.11176V25.5344C32 26.1747 31.4809 26.6938 30.8406 26.6938H28.3039C27.6635 26.6938 27.1444 26.1747 27.1444 25.5344C27.1444 24.8941 27.6635 24.375 28.3039 24.375H29.6812V8.84895L16 2.43976L2.31884 8.84895V24.375H3.94203C4.58236 24.375 5.10145 24.8941 5.10145 25.5344C5.10145 26.1747 4.58236 26.6938 3.94203 26.6938H1.15942C0.51909 26.6938 0 26.1747 0 25.5344V8.11176C0 7.66192 0.260207 7.25268 0.667565 7.06184L15.5081 0.109499Z" fill={color} />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.0755 20.9328C15.0755 20.2924 15.5946 19.7733 16.2349 19.7733H25.2775C25.9178 19.7733 26.4369 20.2924 26.4369 20.9328V29.9753C26.4369 30.6157 25.9178 31.1348 25.2775 31.1348H16.2349C15.5946 31.1348 15.0755 30.6157 15.0755 29.9753V20.9328ZM17.3943 22.0922V28.8159H24.1181V22.0922H17.3943Z" fill={color} />
      <path fillRule="evenodd" clipRule="evenodd" d="M6.03296 20.9328C6.03296 20.2924 6.55205 19.7733 7.19238 19.7733H16.2349C16.8753 19.7733 17.3944 20.2924 17.3944 20.9328V29.9753C17.3944 30.6157 16.8753 31.1348 16.2349 31.1348H7.19238C6.55205 31.1348 6.03296 30.6157 6.03296 29.9753V20.9328ZM8.3518 22.0922V28.8159H15.0755V22.0922H8.3518Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M10.5543 11.8902C10.5543 11.2499 11.0734 10.7308 11.7137 10.7308H20.7563C21.3966 10.7308 21.9157 11.2499 21.9157 11.8902V20.9328C21.9157 21.5731 21.3966 22.0922 20.7563 22.0922H11.7137C11.0734 22.0922 10.5543 21.5731 10.5543 20.9328V11.8902ZM12.8732 13.0496V19.7733H19.5969V13.0496H12.8732Z" fill={color}/>
    </g>
    <defs>
      <clipPath id="clip0_320_162">
        <rect width={width} height={height} fill="white"/>
      </clipPath>
    </defs>
  </svg>
};
export default OnHandBreakdownSvg;