
import { FC } from 'react';

interface GlobalSearchIconProps {
  className?: string
}

const GlobalSearchIcon: FC<GlobalSearchIconProps> = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M12.9 13.6158L8.88333 9.59909C8.55 9.88798 8.16134 10.113 7.71735 10.2741C7.27336 10.4352 6.80091 10.5158 6.3 10.5158C5.0982 10.5158 4.08108 10.0991 3.24865 9.26576C2.41622 8.43242 2 7.42687 2 6.24909C2 5.07131 2.41667 4.06576 3.25 3.23242C4.08333 2.39909 5.09167 1.98242 6.275 1.98242C7.45833 1.98242 8.46389 2.39909 9.29167 3.23242C10.1194 4.06576 10.5333 5.07214 10.5333 6.25159C10.5333 6.7277 10.4556 7.18798 10.3 7.63242C10.1444 8.07687 9.91111 8.49353 9.6 8.88242L13.65 12.8991C13.75 12.9929 13.8 13.108 13.8 13.2444C13.8 13.3809 13.7449 13.5042 13.6348 13.6143C13.5338 13.7153 13.4099 13.7658 13.263 13.7658C13.1161 13.7658 12.9951 13.7158 12.9 13.6158ZM6.28333 9.51576C7.18611 9.51576 7.95347 9.19631 8.58542 8.55742C9.21736 7.91853 9.53333 7.14909 9.53333 6.24909C9.53333 5.34909 9.21736 4.57964 8.58542 3.94076C7.95347 3.30187 7.18611 2.98242 6.28333 2.98242C5.3713 2.98242 4.59607 3.30187 3.95763 3.94076C3.31921 4.57964 3 5.34909 3 6.24909C3 7.14909 3.31921 7.91853 3.95763 8.55742C4.59607 9.19631 5.3713 9.51576 6.28333 9.51576Z"
      fill="currentColor"
    />
  </svg>
);

export default GlobalSearchIcon;